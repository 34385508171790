import React from "react";
import "./styles/style.css";

const Mailto = ({ email, subject, body, children }) => {
  return (
    <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
  );
};

function index() {
  return (
    <div className="hero">
      <div className="taglineBox">
        <div className="taglineBox__rotatedText">
          <p>ADS MADE</p>
        </div>
        <div className="taglineBox__remainingText">
          <p>EASY</p>
          <p>EFFECTIVE</p>
          <p>EFFORTLESS</p>
        </div>
      </div>
      <div className="trynowBtn">
        <a href="http://app.sparktechdesign.com/"> <button className="trynowBtn__button" >Try it for FREE!</button> </a> 
      </div>
      {/* <div className="trynowBtn">
        <a href="https://www.loom.com/share/cf36cfed4c1940fa817317fd432288e5"> <button className="trynowBtn__button" >Watch the Demo</button></a>
      </div> */}
    </div>
    
  );
}

export default index;
