import React from "react";
import "./styles/style.css";

function handleClick() {
  window.location.href = 'http://app.sparktechdesign.com/'; 
};

function Pricing() {
  return (
    <div className="pricing">
      <div className="pricing__title">Pricing</div>
      <div className="pricing__container">
        <div className="pricing__container__box">
          <div className="pricing__container__box__heading">
            <header className="pricing__container__box__head1">Basic</header>
            <header className="pricing__container__box__head2">Free</header>
          </div>
          <div className="pricing__container__box__perks">
            <div>
              {/* SVG icon */}
              <p>5 trials per user</p>
            </div>
            <div>
              {/* SVG icon */}
              <p>Standard Response Speeds</p>
            </div>
            <div>
              {/* SVG icon */}
              <p>Regular Model Updates</p>
            </div>
          </div>
          <div id="clickButton" className="pricing__container__box__btn pricing__container__box__btn--free" onClick={handleClick}>
           Try for Free
          </div>
        </div>
        <div className="pricing__container__box">
          <div className="pricing__container__box__heading">
            <header className="pricing__container__box__head1">Pro</header>
            <header className="pricing__container__box__head2">$49/month</header>
          </div>
          <div className="pricing__container__box__perks">
            <div>
              {/* SVG icon */}
              <p>Unlimited use per month</p>
            </div>
            <div>
              {/* SVG icon */}
              <p>Faster response speed</p>
            </div>
            <div>
              {/* SVG icon */}
              <a className="pricing__container__box__perks__link" href="https://docs.google.com/document/d/1btVojaP71csgJezYGC3fZ1jxdKDBgthWhZUTq3C6-nU/edit?usp=sharing"><p>Click here for Cancellation and Refund Policy</p></a>
            </div>
          </div>
          <div id="clickButton" className="pricing__container__box__btn pricing__container__box__btn--pro" onClick={handleClick}>
            Try AdGPT
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;

