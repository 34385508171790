import './App.css';
import Navbar from "./components/Navbar"
import Hero from "./components/Hero"
import Overview from "./components/Overview"
import About from "./components/About"
import Pricing from "./components/Pricing"
// import ReviewCarousal from "./components/ReviewsCarousal"
import Demo from "./components/Demo"
import Footer from "./components/Footer"
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyAdpzeNZPVTMBKTkvwoyATbCc2jk6K-lt0",
    authDomain: "spark-tech-homepage.firebaseapp.com",
    projectId: "spark-tech-homepage",
    storageBucket: "spark-tech-homepage.appspot.com",
    messagingSenderId: "442362875081",
    appId: "1:442362875081:web:7c774a2296a6aa25c71118",
    measurementId: "G-6RK4S97C2P"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Demo />
      <Overview />
      <About />
      <Pricing />
      {/* <ReviewCarousal /> */}
      <Footer />
    </div>
  );
}

export default App;
