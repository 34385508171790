import React from "react";
import "./styles/style.css";
import ReactPlayer from 'react-player'
import image1 from "../../assets/1.png";
import image2 from "../../assets/2.png";
import image3 from "../../assets/3.png";
import image4 from "../../assets/4.png";
import image5 from "../../assets/5.png";
import image6 from "../../assets/6.png";


function index() {
  return (
    <div className="about">
      <div className="about__content">
        <header></header>
      </div>
      <div className="about__images">
        <div className="demo__images__imagecol">
          <p>Run your Digital Advertisements using AdGPT!</p>            
          <div className="demo__images__imagecol__picture">
            <ReactPlayer width={800} height={500} url={'https://www.youtube.com/watch?v=a_B3CeroWk4'} controls={true} />
          </div>
        </div>
      </div>
    </div>
    // <div className="demo">
    //   <div className="demo__content">
    //     <header>Demo</header>
    //     <div className="demo_content_features">
    //         <ReactPlayer url={'https://www.youtube.com/watch?v=a_B3CeroWk4'} controls={true} />
    //     </div>
    //   </div>
    // </div>
  );
}

export default index;
