import React from "react";
import "./styles/style.css";
import image1 from "../../assets/image 89.png";
import image2 from "../../assets/image 90.png";
import image3 from "../../assets/image 91.png";

function Index() {
  return (
    <div className="overview">
      <div className="overview__product">
        <div className="overview__product__box1">
          <p>Original Ad</p>
          <img src={image1} height="200px" alt="prod1" />
        </div>
        <div className="overview__product__box2">
          <p>AI Generated Images</p>
          <div className="overview__product__box2__images">
            <div>
              <img src={image2} height="200px" alt="" />
            </div>
            <div>
              <img src={image3} height="200px" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="tagline">
        <p className="tagline__header">Get High Conversions</p>
        <p className="tagline__content">
          Our AI platform revamps your ads by adjusting background images,
          making your products pop like never before.
        </p>
      </div>
    </div>
  );
}

export default Index;

