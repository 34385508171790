import React from "react";
import "./styles/style.css";
import navLogo from "../../assets/image 6.png";

function Index() {
  return (
    <div className="navbar">
      <div className="navbar__logo">
        <img src={navLogo} alt=""/>
      </div>
      <div className="navbar__navItems">
        <p>Home</p>
        <p>Pricing</p>
        {/* <p>Reviews</p> */}
      </div>
    </div>
  );
}

export default Index;


