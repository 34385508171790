import React from "react";
import "./styles/style.css";
import image1 from "../../assets/1.png";
import image2 from "../../assets/2.png";
import image3 from "../../assets/3.png";
import image4 from "../../assets/4.png";
import image5 from "../../assets/5.png";
import image6 from "../../assets/6.png";

function index() {
  return (
    <div className="about">
      <div className="about__content">
        <header>Why Us?</header>
        <div className="about_content_features">
          <header>Maximize ROI</header>
          <p>AI generated images offer a lower cost per click.</p>
        </div>
        <div className="about_content_features">
          <header>Rapid Integration</header>
          <p>
            Campaign effectiveness increases when you can generate multiple
            options, test and refine ad visuals quickly.
          </p>
        </div>
        <div className="about_content_features">
          <header>Optimal Efficency</header>
          <p>
            The time saved allows your team to focus on other strategic
            intiatives.
          </p>
        </div>
      </div>
      <div className="about__images">
        <div className="about__images__imagecol">
          <p>Performance of Original Ad Images</p>
          <div className="about__images__imagecol__picture">
            <img src={image1} alt="" />
            <p>CPC-0.47</p>
          </div>
          <div className="about__images__imagecol__picture">
            <img src={image2} alt="" />
            <p>CPC-0.9</p>
          </div>
          <div className="about__images__imagecol__picture">
            <img src={image3} alt="" />
            <p>CPC-0.43</p>
          </div>
        </div>
        <div className="about__images__imagecol">
          <p>Performance of AI Generated Images</p>
          <div className="about__images__imagecol__picture">
            <img src={image4} alt="" />
            <p>CPC-0.41</p>
          </div>
          <div className="about__images__imagecol__picture">
            <img src={image5} alt="" />
            <p>CPC-0.52</p>
          </div>
          <div className="about__images__imagecol__picture">
            <img src={image6} alt="" />
            <p>CPC-0.25</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
